import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.h1}>Privacy Policy</h1>
        <p style={styles.p}>Effective date: October 18, 2024</p>
      </header>

      <section>
        <h2 style={styles.h2}>Introduction</h2>
        <p style={styles.p}>
          Welcome to HEMZ-CRM. This Privacy Policy explains how we collect, use, and safeguard your information when you use our mobile application. By using our app, you agree to the terms of this policy.
        </p>
      </section>

      <section>
        <h2 style={styles.h2}>Information We Collect</h2>
        <ul style={styles.ul}>
          <li><strong>Personal Data:</strong> When you register or use our app, we may collect information like your name, email address, and phone number.</li>
          <li><strong>Usage Data:</strong> We may also collect information about your interactions with the app, such as your IP address, device type, and app usage statistics.</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.h2}>How We Use Your Information</h2>
        <p style={styles.p}>We use the information we collect for purposes such as:</p>
        <ul style={styles.ul}>
          <li>Providing and improving our services</li>
          <li>Sending notifications and updates</li>
          <li>Responding to customer service requests</li>
        </ul>
      </section>

      <section>
        <h2 style={styles.h2}>Sharing Your Information</h2>
        <p style={styles.p}>We do not sell, trade, or otherwise transfer your personal data to third parties, except as required by law or to protect our legal rights.</p>
      </section>

      <section>
        <h2 style={styles.h2}>Security of Your Information</h2>
        <p style={styles.p}>We take appropriate measures to ensure the security of your personal information and protect it from unauthorized access or disclosure.</p>
      </section>

      <section>
        <h2 style={styles.h2}>Your Rights</h2>
        <p style={styles.p}>You have the right to access, modify, or delete your personal data at any time. If you wish to exercise these rights, please contact us at shreechanshree2024@gmail.com.</p>
      </section>

      <section>
        <h2 style={styles.h2}>Changes to This Privacy Policy</h2>
        <p style={styles.p}>We may update this policy from time to time. We will notify you of any changes by updating the "Effective Date" at the top of this page.</p>
      </section>

      <section>
        <h2 style={styles.h2}>Contact Us</h2>
        <p style={styles.p}>
          If you have any questions about this Privacy Policy or other queries, please contact us at:
        </p>
        <p style={styles.p}>Email: shreechanshree2024@gmail.com</p>
      </section>

      <footer style={styles.footer}>
        <p style={styles.p}>&copy; 2024. All rights reserved.</p>
      </footer>
    </div>
  );
};

// CSS Properties using React.CSSProperties
const styles: { [key: string]: React.CSSProperties } = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    textAlign: 'center' as 'center', // Casting the 'textAlign' to 'center'
    marginBottom: '20px',
  },
  h1: {
    color: '#333',
    marginBottom: '10px',
  },
  h2: {
    color: '#007bff',
    marginBottom: '10px',
    borderBottom: '2px solid #f0f0f0',
    paddingBottom: '5px',
  },
  p: {
    marginBottom: '10px',
    color: '#555',
  },
  ul: {
    listStyleType: 'disc',
    paddingLeft: '20px',
    marginBottom: '20px',
  },
  footer: {
    textAlign: 'center' as 'center', // Casting the 'textAlign' to 'center'
    marginTop: '30px',
    paddingTop: '10px',
    borderTop: '1px solid #ccc',
    color: '#777',
  },
};

export default PrivacyPolicy;
